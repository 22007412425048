import {defineStore} from 'pinia'
import {UserData} from '../interfaces/user'
import {clientService} from '../services/client';

export const useUserStore = defineStore('user', {
    state: () => {
        if (localStorage.getItem("lsUser"))
            return JSON.parse(localStorage.getItem("lsUser") as string);

        return {
            user: null as UserData | null,
            logged_in: false,
            token: null as string | null,
        }
    },
    actions: {
        logout() {
            // localStorage.removeItem("lsUser");
            localStorage.clear();

            this.user = null;
            this.logged_in = false;
            this.token = null;
        },

        update(user: UserData) {
            return clientService.update(user);
        },
        updateEmergency(user: UserData, clientId: number) {
            return clientService.updateEmergency(user, clientId);
        },
        updatePrimary(user: UserData, clientId: number) {
            return clientService.updatePrimary(user, clientId);
        },
        updateShipping(user: UserData, clientId: number) {
            return clientService.updateShipping(user, clientId);
        },
        updateGpDetails(user: UserData, clientId: number) {
            return clientService.updateGpDetails(user, clientId);
        },
        updateInsuranceDetails(user: UserData, clientId: number) {
            return clientService.updateInsuranceDetails(user, clientId);
        },

        setUser(user: UserData, clinicLogo = {}) {
            this.user = {...user, ...clinicLogo};
        }
    }
});