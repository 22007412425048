<template>
  <component
      :is="icon"
      v-if="icon"
      :color="icon_color"
      v-bind="getWidthHeight"
  ></component>
</template>

<script>
import {
  AlertIcon,
  BackArrowIcon,
  ChevronIcon,
  ClinicIcon,
  CloseIcon,
  Cross,
  CrossStatus,
  Dots,
  DraggableDotsIcon,
  EditIcon,
  ErrorIcon,
  FilterIcon,
  PeopleIcon,
  PreviewIcon,
  SearchIcon,
  SortIcon,
  ThickIcon,
  TrashIcon,
  MastercardIcon,
  AddPlusCircle,
  UserIcon,
  BuildingIcon,
  FileIcon,
  AllergyIcon,
  VideoIcon,
  PhoneIcon,
  LocationIcon,
  VideoIconLg,
  MessageIcon,
  AddressIcon,
  CardIcon,
  CashIcon,
  CalendarIcon,
  ShippingIcon,
  PackageIcon,
  ServiceIcon,
  ProductIcon,
  PlusIcon,
  ProgramIcon,
  HistoryIcon,
  BasketIcon,
  CartIcon,
  CalendarIconThin,
  FilesIcon,
  MenuIcon,
  ThinBasketIcon,
  BellIcon,
  PersonIcon,
  HomeIcon
} from './components';
import {useSettingsStore} from "@/api/store/settings";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Icon',

  components: {
    'cross-icon': Cross,
    'dots-icon': Dots,
    'error-icon': ErrorIcon,
    'close-icon': CloseIcon,
    'people-icon': PeopleIcon,
    'sort-icon': SortIcon,
    'filter-icon': FilterIcon,
    'add-plus-circle': AddPlusCircle,
    'search-icon': SearchIcon,
    'back-arrow': BackArrowIcon,
    'edit-icon': EditIcon,
    'trash-icon': TrashIcon,
    'draggable-dots': DraggableDotsIcon,
    'alert-icon': AlertIcon,
    'thick-icon': ThickIcon,
    'cross-status': CrossStatus,
    'chevron-icon': ChevronIcon,
    'preview-icon': PreviewIcon,
    'clinic-icon': ClinicIcon,
    'mastercard-icon': MastercardIcon,
    'client-icon': UserIcon,
    'practitioner-icon': BuildingIcon,
    'file-icon': FileIcon,
    'allergy-icon': AllergyIcon,
    'video-icon': VideoIcon,
    'phone-icon': PhoneIcon,
    'location-icon': LocationIcon,
    'video-icon-lg': VideoIconLg,
    'message-icon': MessageIcon,
    'address-icon': AddressIcon,
    'card-icon': CardIcon,
    'cash-icon': CashIcon,
    'calendar-icon': CalendarIcon,
    'shipping-icon': ShippingIcon,
    'package-icon': PackageIcon,
    'service-icon': ServiceIcon,
    'product-icon': ProductIcon,
    'plus-icon': PlusIcon,
    'program-icon': ProgramIcon,
    'history-icon': HistoryIcon,
    'basket-icon': BasketIcon,
    'cart-icon': CartIcon,
    'calendar-icon-thin': CalendarIconThin,
    'files-icon': FilesIcon,
    'menu-icon': MenuIcon,
    'thin-basket': ThinBasketIcon,
    'bell-icon': BellIcon,
    'person-icon': PersonIcon,
    'home-icon': HomeIcon
  },

  props: {
    icon: {
      type: String
    },

    color: {
      type: String,
    },

    width: {
      type: [Number, String]
    },

    height: {
      type: [Number, String]
    }
  },

  data() {
    return {
      colors: {
        grey_light: '#B0B3B7',
        blue_bright: '#1EBCD5',
        green: '#70E591',
        white: '#FFFFFF'
      }
    };
  },

  computed: {
    icon_color() {
      const settingsStore = useSettingsStore();

      if (this.color in this.colors) {
        return this.colors[this.color];
      }

      if (this.color && this.color !== 'primaryButtonColor' && this.color !== 'primaryColor') {
        return this.color;
      }

      if (this.color === 'primaryButtonColor') {
        return settingsStore.settings.buttonColor;
      }

      if (this.color === 'primaryColor') {
        return settingsStore.settings.primaryColor;
      }

      return settingsStore.settings.buttonColor2;
    },

    getWidthHeight() {
      let props = {};

      const vBindProps = ['width', 'height'];

      vBindProps.forEach((propItem) => {
        if (this[propItem] !== undefined) {
          props[propItem] = this[propItem];
        }
      });

      return props;
    }
  }
};
</script>
