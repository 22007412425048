<template>
  <v-container class="px-0 pb-5 document-form" style="background-color:#F0F0F0;min-height:500px;">
    <v-row class="d-flex ga-3 pa-2" no-gutters>
      <v-row no-gutters>
        <v-btn
            :class="{ 'secondary-button': !state.showCompleted}"
            class="b-round-20 transparent"
            @click="toggleView"
        >
          Incomplete
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-btn
            :class="{ 'secondary-button': state.showCompleted }"
            class="b-round-20 transparent"
            @click="toggleView"
        >
          Completed
        </v-btn>
      </v-row>
    </v-row>

    <v-card v-for="document in filteredItems" :key="document.id" class="mt-2 mx-2 mb-2 pa-4 border-rounded">
      <v-row class="my-0" no-gutters>
        <v-col cols="12">
          <v-row>
            <v-col class="px-3">
              <p class="p-text mt-1 name">{{ document.file_resource.title }}</p>

              <p class="p-text mt-1">
                {{ moment(document.created_at).format('DD MMM YYYY') }}
                <span
                    v-if="moment(document.created_at).isBefore(currentDate) && !state.showCompleted"
                    class="small"
                >
                  (Overdue)
                </span>
              </p>
            </v-col>
            <v-col class="text-end px-3">
              <v-btn
                  :class="{
                    'view-button': state.showCompleted,
                    'complete-button': !state.showCompleted
                  }"
                  :color="settingsStore.settings.buttonColor"
                  class="b-round-20"
                  @click="router.push({ name: 'document-viewer', params: { id: document.file_resource.file.id } })"
              >
                {{ state.showCompleted ? 'View' : 'Complete Now' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="filteredItems.length == 0" class="mt-2 mx-2 mb-2 pa-4">
      <v-row class="my-0" no-gutters>
        <v-col cols="12">
          <v-row class="py-2">
            <v-col class="pt-3 px-3">
              <p class="p-text mt-1">No forms available.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed} from 'vue';
import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';
import moment from 'moment';
import {filesService} from '@/api/services/file';

const settingsStore = useSettingsStore();
const router = useRouter();

const currentDate = moment();

const state = reactive({
  items: [] as any[],
  showCompleted: false,
  filesLoaded: false
});

const filteredItems = computed(() => {
  return state.items.filter(item => {
    const hasCompletedDocument = item.file_resource?.file?.client_documents[0]?.status === 'complete';
    return state.showCompleted ? hasCompletedDocument : !hasCompletedDocument;
  });
});

const getFiles = () => {
  settingsStore.loading = true;
  filesService.index(null).then((response) => {
    //Filter out forms in this view
    state.items = response.filter((file: any) => file.file_resource && (
        file.file_resource.kind == 'form'));

  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    state.filesLoaded = true;
    settingsStore.loading = false;
  });
}

const toggleView = () => {
  state.showCompleted = !state.showCompleted;
}

getFiles();
</script>