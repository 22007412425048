<template>
  <v-app :style="bgStyle" class="mx-0">
    <GlobalSnackbar/>

    <top-toolbar v-if="settingsStore.set && userStore.logged_in"/>
    <v-container v-if="settingsStore.set || router.currentRoute.value.name == 'login'" class="pa-0 top-buffer desktop">
      <v-row no-gutters>
        <v-col class="pa-0" cols="12">

          <search-header v-if="settingsStore.settings.header == 1"/>
          <home-branding v-if="settingsStore.settings.header == 2 && router.currentRoute.value.meta.showBrandBar"/>

          <page-header v-if="router.currentRoute.value.meta.showTitleBar" class="mt-2"></page-header>

          <v-main style="padding-top: 0px;">

            <v-slide-x-transition mode="out-in">
              <router-view/>
            </v-slide-x-transition>

          </v-main>
        </v-col>
      </v-row>
      <!-- download-popup v-if="Capacitor.getPlatform() == 'web'" /-->
    </v-container>

    <!--    <splash-screen v-else/>-->

    <v-overlay :model-value="settingsStore.loading" class="align-center justify-center" color="deep-purple-accent-4">
      <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-overlay :model-value="settingsStore.error" class="align-center justify-center" color="red">
      <v-card class="pa-5">
        <v-card-title class="text-center">Error</v-card-title>
        <v-card-text class="text-center">{{ settingsStore.error_message }}</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="settingsStore.error = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-app>
</template>

<script lang="ts" setup>
import {computed, onMounted, watch} from 'vue';
import {useRouter} from 'vue-router';
import TopToolbar from './components/Layout/TopToolbar.vue';
import SearchHeader from '@/components/Layout/SearchHeader.vue';
import HomeBranding from './views/HomeComponents/HomeBranding.vue';
import SplashScreen from './views/HomeComponents/SplashScreen.vue';
import PageHeader from '@/components/Layout/PageHeader.vue';

import {useSettingsStore} from '@/api/store/settings';
import {useUserStore} from './api/store/user';

import {appService} from '@/api/services/app';

import {Capacitor} from '@capacitor/core';
import {performImmediateUpdate} from '@/plugins/updater';

import {NativeBiometric} from "capacitor-native-biometric";

import DownloadPopup from '@/components/custom/DownloadPopup.vue';
import {setUser} from "@/api/services/auth";
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";

const router = useRouter();

const performBiometricVerification = async () => {
  const result = await NativeBiometric.isAvailable();

  if (!result.isAvailable) return;

  const verified = await NativeBiometric.verifyIdentity({
    reason: "Secure Login",
    title: "Log in",
    subtitle: "We just need to verify your identity.",
    description: "Please use your biometric authentication method to log in.",
  })
      .then(() => true)
      .catch(() => false);

  if (!verified) return false;

  return true;
}

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const appStart = async () => {

  console.log("App Starting");

  if (Capacitor.getPlatform() == "web") {
    console.log("Running on Web Mode");
  } else {
    console.log("Running on Mobile");
    console.log("Checking for updates");
    performImmediateUpdate();
  }

  if (userStore.logged_in && (Capacitor.getPlatform() == "web" || await performBiometricVerification())) {
    console.log("User Already Logged In");
    console.log("Fetching App Configuration");

    await appService.fetchDisplay().then((response: any) => {

      userStore.setUser(response.client, response.clinicLogo);
      setUser(response.client);

      if (!response.app.settings || response.app.settings == "null") {
        return;
      }

      settingsStore.settings = JSON.parse(response.app.settings);
    }).catch((err: any) => {
      console.log("Display Error: ", err);
      if (err.response?.status === 401) {
        router.push({name: 'login'});
      }
    });
  }

  setTimeout(() => {
    settingsStore.set = true;
  }, 1000);
};

// Update CSS variables globally based on the settings
const updateCSSVariables = () => {
  const primaryColor = settingsStore.settings.primaryColor;
  const buttonColor = settingsStore.settings.buttonColor;
  const buttonColor2 = settingsStore.settings.buttonColor2;

  document.documentElement.style.setProperty('--primary-color', primaryColor);
  document.documentElement.style.setProperty('--primary-button-color', buttonColor);
  document.documentElement.style.setProperty('--secondary-button-color', buttonColor2);
};

onMounted(() => {
  router.isReady().then(() => {
    if (router.currentRoute.value.name && router.currentRoute.value.name !== 'preview') {
      console.log("Route: ", router.currentRoute.value.name);
      appStart();
    } else {
      console.log("Route: ", router.currentRoute.value.name);
      settingsStore.set = true;
    }
  });
});

const bgStyle = computed(() => {
  if (!settingsStore.settings) return 'background-color: rgba(255, 255, 255, 0.3);'; // 30% opacity
  if (router.currentRoute.value.meta.showBrandBar || router.currentRoute.value.meta.withSettingsBackground) {
    return 'background: linear-gradient(180deg, ' + hexToRgba(settingsStore.settings.primaryColor, 0.3) + '0, ' + 'rgba(255, 255, 255, 0.3)' + ' 50% 100%);';
  }
  return 'background-color: rgba(255, 255, 255, 0.3);';
});

// Helper function to convert hex color to rgba with the specified opacity
function hexToRgba(hex, opacity) {
  let r = 0, g = 0, b = 0;
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}

watch(settingsStore, () => {
  updateCSSVariables();
})

</script>

<style lang="scss">
@import 'assets/scss/styles.scss';

.desktop {
  max-width: 480px;
  margin: 0 auto;
}

.top-buffer {
  margin-top: 40px;
}

.layout1-bg {
  background-color: white;
}

.number-control {
  margin-left: 0;
  margin-right: 0;
  width: 120px;
  text-align: center;
}

.number-control-minus {
  margin: 0;
  padding: 0;
}

.number-control-plus {
  margin: 0;
  padding: 0;
}
</style>
