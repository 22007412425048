<template>
  <v-container class="px-0 pt-0 pb-5" style="background-color:#EEE;min-height:500px;">
    <v-sheet class="d-flex justify-center pa-4 text-center">
      <v-sheet :style="buttonStyle" class="pa-3 mx-2 b-round-20" elevation="3"
               width="100" @click="router.push({name: 'document-documents' })">
        <v-icon class="my-2" icon="mdi mdi-file-document-outline" size="40"></v-icon>
        <p class="p-text-md">Documents</p>
      </v-sheet>
      <v-sheet :style="buttonStyle" class="pa-3 mx-2 b-round-20" elevation="3"
               width="100" @click="router.push({name: 'document-forms'})">
        <v-icon class="my-2" icon="mdi mdi-file-document-edit-outline" size="40"></v-icon>
        <p class="p-text-md">Forms</p>
      </v-sheet>
      <v-sheet :style="buttonStyle" class="pa-3 mx-2 b-round-20" elevation="3"
               width="100" @click="router.push({name: 'document-surveys'})">
        <v-icon class="my-2" icon="mdi mdi-chart-box-outline" size="40"></v-icon>
        <p class="p-text-md">Surveys</p>
      </v-sheet>
    </v-sheet>
    <v-card class="mx-4 mt-2">
      <v-card-title class="text-h6">Recent Activities</v-card-title>
      <v-card-text>
        <!--p class="p-text" v-for="activity in state.activities" :key="activity.id">{{ activity }}.</p-->
        <p class="p-text">No recent activities.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed} from 'vue';

import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';

import {activityService} from '@/api/services/activity';

const router = useRouter();

const settingsStore = useSettingsStore();

const state = reactive({
  activities: [] as any[],
  search: "" as string,
  sort_asc: true as boolean,
  page: 1 as number,
  tab: "home" as string,
});

const buttonStyle = computed(() => {
  return "background: linear-gradient(180deg, rgba(30, 188, 213, 0.05) 5%, rgba(255, 255, 255, 0) 100%); color: " + settingsStore.settings.primaryColor + ";";
});

const activeButtonStyle = computed(() => {
  return "background: " + settingsStore.settings.primaryColor + "; color: white;";
});

const getActivities = () => {
  settingsStore.loading = true;
  activityService.index(null).then((response) => {
    state.activities = response.data;
    settingsStore.loading = false;
  });
}

getActivities();
</script>