<template>
  <v-container v-if="state.item" class="py-3 mt-3" style="padding-left:0px;padding-right:0px;">
    <v-img :src="state.item.images[0]?.full" height="300" width="100%"></v-img>
    <v-card class="mt-3 mx-0">
      <v-card-title class="item-header"><b>{{ state.item.name }}</b></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="4">
            <p class="panel-title">Brand:</p>
          </v-col>
          <v-col cols="6">
            <p class="panel-content">{{ state.item.brand.name }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="4">
            <p class="panel-title">Product ID: </p>
          </v-col>
          <v-col cols="6">
            <p class="panel-content">{{ state.item.sku }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="4">
            <p class="panel-title">Format: </p>
          </v-col>
          <v-col cols="6">
            <p class="panel-content">{{ state.item.package_qty }} {{ state.item.package_unit }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="4">
            <p class="panel-title">Price </p>
          </v-col>
          <v-col cols="6">
            <p class="panel-content"><b>£{{ state.item.price.toFixed(2) }}</b></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="itemInfo in state.PanelData" :key="itemInfo.title">
          <v-expansion-panel-title>
            <p class="panel-title">{{ itemInfo.title }}</p>
            <template v-slot:actions>
              <v-icon class="panel-arrow" icon="fa-chevron-down">
              </v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="my-2 text-subtitle-1" v-html="itemInfo.content">

            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card class="py-2 mb-2">
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn class="text-white add-to-cart-btn mx-auto d-inline b-round-20" style="margin-top:8px;width:200px"
                   variant="outlined"
                   @click="addProduct">
              <span>Add to cart</span>
            </v-btn>
          </v-col>
          <v-col>
            <quantity-editor v-model="state.qty"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {productService} from '@/api/services/product';
import {cartService} from '@/api/services/cart';
import {useSettingsStore} from '@/api/store/settings';
import {useCartStore} from '@/api/store/cart';
import QuantityEditor from '@/components/QuantityEditor.vue';
import {loadPanelData} from "@/views/ShopComponents/ItemComponents/PanelDataForSupplier";

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const route = useRoute();
const router = useRouter();

const state = reactive({
  item: null as any,
  qty: 1 as number,
  fav: false as boolean,
  PanelData: [] as any[],
});

const SUPPLIER_INDEX = 1;

settingsStore.loading = true;
productService.getProduct(+route.params.id).then((response) => {
  state.item = response.data;
  router.currentRoute.value.meta.title = state.item.name;
  state.PanelData = loadPanelData(state.item);
  settingsStore.loading = false;
}).catch((err) => {
  settingsStore.loading = false;
  //router.push({ name: 'shop' });
});

const addProduct = () => {
  settingsStore.loading = true;
  cartStore.addItem(SUPPLIER_INDEX, state.item.id, state.qty).then((res) => {
    console.log(res);
    router.push({name: 'shopping-cart'});
  }).catch((error) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
};
</script>

<style>
.add-to-cart-btn {
  background-color: #C671C2;
}

.panel-arrow {
  color: #1EBCD5;
}

.item-header {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
}

.panel-title {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #838383;
}

.panel-content {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
</style>

<style lang="scss" scoped>

.v-col {
  padding: 4px !important;
}

</style>