<template>
  <v-col class="d-flex flex-column align-center">
    <button
        :class="`position-relative round-primary-button ${customClass}`"
        @click="emit('click')"
    >
      <Icon :color="color" :icon="icon"></Icon>

      <p v-if="badgeContent" class="position-absolute badge">
        {{ badgeContent }}
      </p>
    </button>
    
    <p class="pt-2">{{ label }}</p>
  </v-col>
</template>

<script lang="ts" setup>
import Icon from "@/components/Icons/Icon.vue";
import {defineEmits, defineProps} from 'vue';

const emit = defineEmits(['click']);

const props = defineProps({
  icon: {
    type: String,
  },
  label: {
    type: String,
  },
  color: {
    type: String,
    default: 'white',
  },
  customClass: {
    type: String,
    default: 'primary-button-gradient',
  },
  badgeContent: {
    type: [Number],
  },
})
</script>

<style scoped>
.badge {
  background: linear-gradient(0deg, #EC5151, #EC5151),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -3px;
  position: absolute;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>